import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"

const ThanksDownload = () => {
  return (
    <Layout lang="en-US">
      <Seo
        pagetitle="Information Request Completed"
        pagepath="/thanks-download"
        pagedesc="Information Request Completed | PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
      />
      <section className="thanks">
        <div className="l-container">
          <h3 className="thanks__title">
            Thank you for downloading our materials!
          </h3>
          <p className="thanks__subtitle">
            We will send you a link to download the materials to the e-mail
            address you provide.
            <br />
            Free online consultations are also available. If you would like to
            schedule a consultation, please select your preferred date and time
            from the form below.
          </p>
          <div className="thanks__btn">
            <a
              className="btn--free-consulting"
              href="https://hub.park-lot.com/free-consulting"
              target="_blank"
              rel="noreferrer"
            >
              Sign up for a free consultation
            </a>
          </div>
          <hr className="thanks__hr" />
          <div className="qa-list__box">
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                Can you really offer multiple campaigns at this price? Will
                there be no additional costs?
              </dt>
              <dd className="qa-list__data">
                If you sign up for a fixed price plan, you can campaign as many
                times as you wish at no additional cost.
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                What is the process to start the campaign?
              </dt>
              <dd className="qa-list__data">
                The basic flow is: application - data preparation and payment -
                campaign creation - campaign start.
              </dd>
              <dd className="qa-list__detail">
                <a href="/qa/starting-a-campaign" className="qa-list__link">
                  Read more
                </a>
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                Is it possible to check the actual operation of the management
                screen and the participants' guide lines on the demo screen,
                etc. before applying?
              </dt>
              <dd className="qa-list__data">
                Yes, it is possible. Please contact us for more information.
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                Do I need to follow any procedures for cancellation?
              </dt>
              <dd className="qa-list__data">No special requirements.</dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                What is the maximum number of retweeted user data that can be
                collected?
              </dt>
              <dd className="qa-list__data">
                We can collect more than 100,000 cases per day. If you need to
                collect more than that, please contact us.
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                To what extent can I obtain information on Twitter accounts that
                participated in the campaign?
              </dt>
              <dd className="qa-list__data">
                Basically, we can collect profile data that is publicly
                available on Twitter. Information that is set to private on
                individual accounts cannot be collected.
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                Is there a mechanism for approaching users who participated in
                the campaign but were not selected in the drawing?
              </dt>
              <dd className="qa-list__data">
                Yes, we do. The campaign can be designed to direct visitors to
                LINE, Instagram, or the company's website.
                <br />
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                Can you assist me in obtaining a certification badge?
              </dt>
              <dd className="qa-list__data">
                Yes, we can. Please contact us for details.
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThanksDownload
